<template>
  <div>
    <v-card class="px-5 pt-4 pb-2" flat>
      <v-form ref="form" lazy-validation>
        <v-row class="my-0">
          <v-col class="py-0" cols="12">
            <div class="font-weight-bold mb-2">Tên chi nhánh</div>
            <tp-text-field
              v-model="branch.name"
              placeholder="Nhập tên chi nhánh"
              validate="required"
            ></tp-text-field>
          </v-col>

          <v-col class="py-0" md="6" sm="12">
            <div class="font-weight-bold mb-2">Số điện thoại</div>
            <tp-text-field
              v-model="branch.phone"
              placeholder="Nhập số điện thoại"
              validate="required|tel"
            ></tp-text-field>
          </v-col>

          <v-col class="py-0" md="6" sm="12">
            <div class="font-weight-bold mb-2">Loại chi nhánh</div>
            <tp-select
              v-model="branch.type"
              :items="branchTypes"
              item-text="name"
              item-value="id"
              placeholder="Chọn loại chi nhánh"
              validate="required"
            ></tp-select>
          </v-col>

          <v-col class="py-0" cols="12">
            <div class="font-weight-bold mb-2">Tỉnh/Thành phố</div>
            <tp-select
              v-model="branch.tinh_thanhpho"
              :items="cities"
              item-text="name"
              item-value="name"
              placeholder="Chọn tỉnh/thành phố"
              validate="required"
            ></tp-select>
          </v-col>

          <v-col class="py-0" cols="12">
            <div class="font-weight-bold mb-2">Địa chỉ</div>
            <tp-text-field
              v-model="branch.address"
              placeholder="Nhập địa chỉ"
              validate="required"
            ></tp-text-field>
          </v-col>

          <v-col class="py-0" cols="12">
            <div class="font-weight-bold mb-2">Đường dẫn Google Map</div>
            <tp-text-field
              v-model="branch.map_url"
              placeholder="Nhập đường dẫn Google Map"
              validate="required"
            ></tp-text-field>
          </v-col>
        </v-row>
      </v-form>
    </v-card>

    <div class="mt-5">
      <v-btn
        v-if="branch.id"
        color="primary"
        dark
        depressed
        :loading="branchStatusRequest.value === 'loading-updateBranch'"
        @click="
          branchStatusRequest.value === 'loading-updateBranch'
            ? null
            : updateBranch()
        "
      >
        Lưu
      </v-btn>
      <v-btn
        v-else
        color="primary"
        dark
        depressed
        :loading="branchStatusRequest.value === 'loading-createBranch'"
        @click="
          branchStatusRequest.value === 'loading-createBranch'
            ? null
            : createBranch()
        "
      >
        Thêm
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      branchTypes: [
        { id: 1, name: "Bán/Kho" },
        { id: 2, name: "Chỉ kho" },
        { id: 3, name: "Kho máy lỗi hỏng" },
        { id: 4, name: "Kho máy bảo hành" }
      ]
    };
  },

  computed: {
    branch() {
      return this.$store.getters["BRANCH/branch"];
    },
    branchStatusRequest() {
      return this.$store.getters["BRANCH/statusRequest"];
    },
    cities() {
      return this.$store.getters["LOCAL_ADMINISTRATION/cities"];
    }
  },

  async created() {
    if (this.cities.length === 0) {
      await this.$store.dispatch("LOCAL_ADMINISTRATION/getCities");
    }
  },

  methods: {
    closeModal() {
      this.$modal.hide({
        name: "modal-branch"
      });
      this.$store.dispatch("BRANCH/resetBranch");
      this.activeTab = null;
    },

    async createBranch() {
      const isValid = this.$refs.form.validate();

      if (!isValid) return;

      const routeQuery = this.$route.query;
      // Request create
      await this.$store.dispatch("BRANCH/createBranch", {
        data: this.branch,
        routeQuery: {
          search: routeQuery.search || null,
          per_page: parseInt(routeQuery.limit),
          cur_page: parseInt(routeQuery.page)
        }
      });
      // Alert
      if (this.branchStatusRequest.value === "success-createBranch") {
        this.$toast.show({
          name: "toast-action-alert",
          payload: {
            message: "Thêm chi nhánh thành công"
          }
        });
      } else if (this.branchStatusRequest.value === "error-updateBranch") {
        this.$modal.show({
          name: "modal-alert",
          payload: {
            title: `<span class="red--text text--accent-2">${this.branchStatusRequest.message}</span>`,
            cancelBtnText: "OK"
          }
        });
      }
    },

    async updateBranch() {
      const isValid = this.$refs.form.validate();

      if (!isValid) return;

      const routeQuery = this.$route.query;
      // Request create
      await this.$store.dispatch("BRANCH/updateBranch", {
        data: this.branch,
        routeQuery: {
          search: routeQuery.search || null,
          per_page: parseInt(routeQuery.limit),
          cur_page: parseInt(routeQuery.page)
        }
      });
      // Alert
      if (this.branchStatusRequest.value === "success-updateBranch") {
        this.$toast.show({
          name: "toast-action-alert",
          payload: {
            message: "Cập nhật chi nhánh thành công"
          }
        });
      } else if (this.branchStatusRequest.value === "error-updateBranch") {
        this.$modal.show({
          name: "modal-alert",
          payload: {
            title: `<span class="red--text text--accent-2">${this.branchStatusRequest.message}</span>`,
            cancelBtnText: "OK"
          }
        });
      }
    }
  }
};
</script>
