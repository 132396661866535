<template>
  <v-data-table
    class="tp-table-scroll__with-title tp-table__row-pointer datatable px-3 py-2"
    calculate-widths
    disable-pagination
    no-data-text="Không có dữ liệu"
    no-results-text="Không tìm thấy kết quả phù hợp"
    :headers="headers"
    hide-default-footer
    :loading="branchStatusRequest.value === 'loading-getBranches'"
    loading-text="Đang tải dữ liệu"
    :items="branches"
    item-key="id"
    @click:row="viewDetail($event)"
  >
    <template v-slot:[`item.phone`]="{ item }">
      <span v-if="item.phone">{{ item.phone | VMask("### ### ####") }}</span>
      <span v-else>Không có</span>
    </template>

    <template v-slot:[`item.type`]="{ item }">
      {{ branchTypes[item.type - 1].name }}
    </template>

    <template v-slot:[`item.action`]="{ item }">
      <div class="d_flex justify-center alight-center">
        <v-hover v-slot:default="{ hover }">
          <v-btn
            class="mr-2"
            :color="hover ? 'grey darken-1' : 'grey lighten-1'"
            icon
            @click.stop="viewDetail(item)"
          >
            <v-icon>mdi-square-edit-outline</v-icon>
          </v-btn>
        </v-hover>
        <v-hover v-slot:default="{ hover }">
          <v-btn
            :color="hover ? 'grey darken-1' : 'grey lighten-1'"
            icon
            @click.stop="deleteBranch(item)"
          >
            <v-icon>mdi-trash-can-outline</v-icon>
          </v-btn>
        </v-hover>
      </div>
    </template>
  </v-data-table>
</template>

<script>
import { BANK_ACCOUNT_OBJECT_TYPE } from "@/core/constant";

export default {
  props: {
    selectedBrands: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      branchTypes: [
        { id: 1, name: "Bán/Kho" },
        { id: 2, name: "Chỉ kho" },
        { id: 3, name: "Kho máy lỗi hỏng" },
        { id: 4, name: "Kho máy bảo hành" }
      ],
      headers: [
        {
          text: "Mã chi nhánh",
          align: "start",
          sortable: false,
          value: "code"
        },
        {
          text: "Tên chi nhánh",
          align: "start",
          sortable: false,
          value: "name"
        },
        {
          text: "SĐT liên hệ",
          align: "start",
          sortable: false,
          value: "phone"
        },
        {
          text: "Địa chỉ",
          align: "start",
          sortable: false,
          value: "address"
        },
        {
          text: "Loại",
          align: "start",
          sortable: false,
          value: "type"
        },
        {
          text: "",
          align: "start",
          sortable: false,
          value: "action"
        }
      ]
    };
  },
  computed: {
    branches() {
      return this.$store.getters["BRANCH/branches"];
    },
    branchStatusRequest() {
      return this.$store.getters["BRANCH/statusRequest"];
    }
  },
  filters: {
    formatCurrency(value) {
      // Create our number formatter.
      let formatter = new Intl.NumberFormat("vi-VN", {
        style: "currency",
        currency: "VND"
      });

      return formatter.format(value);
    }
  },
  methods: {
    copyToClipboard(str) {
      const el = document.createElement("textarea");

      el.value = str;
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);

      this.$toast.show({
        name: "toast-action-alert",
        payload: {
          message: "Đã sao chép vào bộ nhớ tạm"
        }
      });
    },

    async deleteBranch(item) {
      this.$modal.show({
        name: "modal-alert",
        payload: {
          title: `<span>Xóa chi nhánh <strong>"${item.name}"</strong></span>`,
          message:
            "Hành động này sẽ không thể hoàn tác. Bạn có chắc chắn muốn tiếp tục?",
          confirmBtn: {
            color: "red accent-2",
            text: "Xóa",
            onClickHandler: async () => {
              const routeQuery = this.$route.query;

              await this.$store.dispatch("BRANCH/deleteBranch", {
                data: item.id,
                routeQuery: {
                  search: routeQuery.search || null,
                  per_page: parseInt(routeQuery.limit),
                  cur_page: parseInt(routeQuery.page)
                }
              });

              if (this.branchStatusRequest.value === "success-deleteBranch") {
                this.$modal.hide({ name: "modal-branch" });
                // Alert
                this.$toast.show({
                  name: "toast-action-alert",
                  payload: {
                    message: "Đã xóa thành công"
                  }
                });
              } else {
                if (this.branchStatusRequest.value === "error-deleteBranch") {
                  this.$modal.hide({ name: "modal-branch" });
                  // Alert
                  this.$toast.show({
                    name: "toast-action-alert",
                    payload: {
                      message: "Lỗi xóa chi nhánh :("
                    }
                  });
                }
              }
            }
          }
        }
      });
    },

    getIndexOfBranch(id) {
      const arr = this.branches.map(item => item.id);

      return arr.indexOf(id);
    },

    async viewDetail(item) {
      await this.$store.dispatch("BRANCH/setBranch", { ...item });
      // Open modal
      this.$modal.show({
        name: "modal-branch",
        payload: {
          index: this.getIndexOfBranch(item.id)
        }
      });

      this.$store.dispatch("BANK_ACCOUNT/getObjectBankAccounts", {
        id: item.id,
        type: BANK_ACCOUNT_OBJECT_TYPE.BRANCH
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.datatable {
  .v-data-table-header {
    background-color: green;
  }
  .copied-label {
    cursor: pointer;
    display: inline-block;
  }
  .view-label {
    cursor: pointer;
    transition: all 0.4s ease;
  }
}
</style>
