<template>
  <div>
    <form-bank-account
      v-for="item in branchBankAccounts"
      :bank-account="item"
      class="mb-4"
      :key="item.id"
      :object-id="branch.id"
      :object-type="BANK_ACCOUNT_OBJECT_TYPE.BRANCH"
    />
    <form-bank-account
      :bank-account="emptyBranchBankAccount"
      input-mode
      :object-id="branch.id"
      :object-type="BANK_ACCOUNT_OBJECT_TYPE.BRANCH"
    />
  </div>
</template>

<script>
import FormBankAccount from "@/core/components/form/FormBankAccount/index.vue";
import {
  BANK_ACCOUNT_OBJECT_TYPE,
  INIT_OBJECT_BANK_ACCOUNT_VALUE
} from "@/core/constant";

export default {
  components: { FormBankAccount },

  data() {
    return {
      BANK_ACCOUNT_OBJECT_TYPE: BANK_ACCOUNT_OBJECT_TYPE,
      emptyBranchBankAccount: JSON.parse(
        JSON.stringify(INIT_OBJECT_BANK_ACCOUNT_VALUE)
      )
    };
  },

  computed: {
    branch() {
      return this.$store.getters["BRANCH/branch"];
    },

    branchBankAccounts() {
      return this.$store.getters["BANK_ACCOUNT/objectBankAccounts"];
    }
  }
};
</script>
