<template>
  <tp-modal name="modal-branch" width="70%" max-width="960px">
    <v-card>
      <v-toolbar
        class="px-5 pb-0 align-start"
        height="56x"
        extension-height="40px"
      >
        <v-toolbar-title class="font-weight-bold">
          {{ branch.id ? "Sửa chi nhánh" : "Thêm chi nhánh" }}
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn
          icon
          color="red accent-1"
          v-if="branch.id"
          @click="deleteBranch()"
        >
          <v-icon>mdi-trash-can-outline</v-icon>
        </v-btn>

        <v-btn icon @click="closeModal()">
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <template v-slot:extension>
          <v-tabs v-model="activeTab" height="40px">
            <template v-if="!branch.id">
              <v-tab
                class="font-weight-bold"
                v-for="item in tabItems.slice(0, 1)"
                :key="item.id"
              >
                {{ item.text }}
              </v-tab>
            </template>
            <template v-else>
              <v-tab
                class="font-weight-bold"
                v-for="item in tabItems"
                :key="item.id"
              >
                {{ item.text }}
              </v-tab>
            </template>
          </v-tabs>
        </template>
      </v-toolbar>

      <v-tabs-items class="modal-body-scroll" v-model="activeTab">
        <v-tab-item
          class="tab-item grey lighten-3 pa-5"
          v-for="item in tabItems"
          :key="item.id"
        >
          <component
            :is="
              item.id === 'bank-account' && !branch.id ? null : `tab-${item.id}`
            "
          ></component>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </tp-modal>
</template>

<script>
import TabBankAccount from "./components/TabBankAccount";
import TabInfo from "./components/TabInfo";

export default {
  components: {
    TabBankAccount,
    TabInfo
  },

  data() {
    return {
      activeTab: null,
      tabItems: [
        { id: "info", text: "Thông tin" },
        { id: "bank-account", text: "Tài khoản giao dịch" }
      ]
    };
  },

  computed: {
    branch() {
      return this.$store.getters["BRANCH/branch"];
    }
  },

  created() {
    this.activeTab = 1;
  },

  methods: {
    closeModal() {
      this.$store.dispatch("BRANCH/resetBranch");
      this.$modal.hide({
        name: "modal-branch"
      });

      this.activeTab = null;
    },

    async deleteBranch() {
      this.$modal.show({
        name: "modal-alert",
        payload: {
          title: `<span>Xóa chi nhánh <strong>"${this.branch.name}"</strong></span>`,
          message:
            "Hành động này sẽ không thể hoàn tác. Bạn có chắc chắn muốn tiếp tục?",
          confirmBtn: {
            color: "red accent-2",
            text: "Xóa",
            onClickHandler: async () => {
              const routeQuery = this.$route.query;

              await this.$store.dispatch("BRANCH/deleteBranch", {
                data: this.branch.id,
                routeQuery: {
                  search: routeQuery.search || null,
                  per_page: parseInt(routeQuery.limit),
                  cur_page: parseInt(routeQuery.page)
                }
              });

              if (this.branchStatusRequest.value === "success-deleteBranch") {
                this.$modal.hide({ name: "modal-branch" });
                // Alert
                this.$toast.show({
                  name: "toast-action-alert",
                  payload: {
                    message: "Đã xóa thành công"
                  }
                });
              } else {
                if (this.branchStatusRequest.value === "error-deleteBranch") {
                  this.$modal.hide({ name: "modal-branch" });
                  // Alert
                  this.$toast.show({
                    name: "toast-action-alert",
                    payload: {
                      message: "Lỗi xóa chi nhánh :("
                    }
                  });
                }
              }
            }
          }
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.tab-item {
  min-height: 500px;
}
</style>
